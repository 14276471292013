/* global tw */
import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TerminalItem from './terminal-item'

const Title = styled('div')`
  ${tw('relative h-8 pt-1 rounded-t-lg text-center')};
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #ebebeb, color-stop(1, #d5d5d5)));
  background: -webkit-linear-gradient(top, #ebebeb, #d5d5d5);
  background: -moz-linear-gradient(top, #ebebeb, #d5d5d5);
  background: -ms-linear-gradient(top, #ebebeb, #d5d5d5);
  background: -o-linear-gradient(top, #ebebeb, #d5d5d5);
  background: linear-gradient(top, #ebebeb, #d5d5d5);
`

const Controls = styled('span')`
  ${tw('absolute hidden sm:block')};
  top: -1px;
  left: 5px;
`

const Command = styled('div')`
  ${tw('overflow-y-auto mt-0 text-xs sm:text-sm shadow whitespace-pre-wrap')};
  font-family: 'Source Code Pro', monospace;
  counter-reset: line;
  @media (min-width: 576px) {
    max-height: 92vh;
  }
`

const Cursor = styled('span')`
  ${tw('inline-block w-2 h-4 bg-gray-800 align-middle')};
  content: '';
  -webkit-animation: blink 1s step-end infinite;
  animation: blink 1s step-end infinite;
  @-webkit-keyframes blink {
    50% {
      opacity: 0;
    }
  }
  @keyframes blink {
    50% {
      opacity: 0;
    }
  }
`

const Button = styled('div')`
  ${tw('inline-block w-3 h-3 mx-1 mt-3 bg-red-500 rounded-lg')};
  ${(props) => (props.minimize ? tw('bg-yellow-500') : '')};
  ${(props) => (props.maximize ? tw('bg-green-500') : '')};
`

const Terminal = (props) => {
  const { icon, title, command, children } = props

  return (
    <>
      <Title>
        <Controls>
          <Button />
          <Button minimize />
          <Button maximize />
        </Controls>
        <FontAwesomeIcon icon={icon} /> {title} &mdash; -bash &mdash; 42x
        {React.Children.count(children) + 6}
      </Title>
      <Command>
        <TerminalItem>
          Last login: {new Date().toDateString().slice(0, -5)}{' '}
          {new Date().toLocaleTimeString(undefined, { hour12: false })} on ttys008
        </TerminalItem>
        <TerminalItem>
          Marcs-MacBook-Pro:~ marc$ ./
          {command}
          .sh
        </TerminalItem>
        <TerminalItem>==========================================</TerminalItem>
        <TerminalItem>
          {'                 //'}
          \/\arc
        </TerminalItem>
        <TerminalItem>==========================================</TerminalItem>
        {children}
        <TerminalItem id="prompt">
          Marcs-MacBook-Pro:~ marc$ <Cursor />
        </TerminalItem>
      </Command>
    </>
  )
}

Terminal.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  command: PropTypes.string.isRequired,
  children: PropTypes.array.isRequired,
}

export default Terminal
