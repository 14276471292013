/* global tw */
import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const Container = styled('p')`
  ${tw('block my-0 leading-normal')};
  &:before {
    ${tw('inline-block px-1 mr-2')};
    border-right: 1px solid #2d3748;
    counter-increment: line;
    content: counter(line);
  }
  &:nth-of-type(-n + 99) {
    :before {
      content: ' ' counter(line);
    }
  }
  &:nth-of-type(-n + 9) {
    :before {
      content: '  ' counter(line);
    }
  }
`

const TerminalItem = ({ id, children }) => <Container id={id}>{children}</Container>

TerminalItem.propTypes = {
  id: PropTypes.any,
  children: PropTypes.any,
}

TerminalItem.defaultProps = {
  id: null,
  children: '',
}

export default TerminalItem
